import { Req } from '@cbo/shared-library';
import { ActivationTypes, ActivationTypes as EmploymentStatus } from '@cbo/shared-library/types/activation';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { MultiQueryParam, SingleQueryParam } from '@cbo/shared-library/request/firebase.request';
import { useSites } from '../../contexts/siteContext';
import useCallBslAndHandleErrors from '../../lib/firebaseApiHandleErrors';
import { Paginated, PaginationResponse } from '../../models/Paginated';
import {
  AddPayRateRequestBody,
  ContactInformation,
  EmergencyContactInformation,
  Employee,
  EmployeeCertification,
  EmployeeConfigurationsResponse,
  EmployeeJobCodeAssignment,
  JobCodeDefinition,
  PatchPayRateRequestBody,
  PayRate,
  PersonalInformation,
} from '../services/employeeService';
import {
  ApproachingACAHourlyThresholdGridDataRequest,
  ApproachingACAHourlyThresholdGridDataResponse,
  ContactInfoUpdateRequestSchema,
  EmployeeBreakReportCommonRequestSchema,
  EmployeeBreakReportGridDataResponse,
  EmployeeBreakReportKpiCardsDataResponse,
  EmployeeRequestSchema,
  EmployeeSalesPerformanceCommonRequest,
  EmployeeSalesPerformanceGridDataResponse,
  EmployeeSalesPerformanceKpiCardsResponse,
  EmployeeSelfRequestSchema,
  GenericPayrollExportGridDataRequest,
  GenericPayrollExportGridDataResponse,
  IntervalSalesAndLaborAverageSalesResponse,
  IntervalSalesAndLaborGridDataResponse,
  IntervalSalesAndLaborKPIResponse,
  IntervalSalesAndLaborReportCommonRequestSchema,
  JobcodeHistoryGridRequest,
  JobcodeHistoryGridResponse,
  LaborGroupSchema,
  OvertimeThresholdCommonRequest,
  OvertimeThresholdGridDataResponse,
  OvertimeThresholdKpiCardsResponse,
  AcaDashboardKPICardsRequest,
  AcaDashboardKPICardsResponse,
  UpdateEmergencyContactRequestSchema,
  UpdateEmployeeCertificationSchema,
  UpdateEmployeeConfigurationSchema,
  UpdateEmployeeJobCodeAssignmentSchema,
  UpdateEmployeePermissionsSchema,
  UpdateJobcodeSchema,
  UpdatePersonalInfoSchema,
  UpdateResponse,
  ApproachingACAThresholdAverageHoursWorkedGridResponse,
  EmployeeIdByPosEmployeeIdResponse,
  EmployeePayrollDto,
  EmployeePayrollGridResponse,
  EmployeePayrollDetailDataGridResponse,
  EmployeePayrollKPICardData,
  EmployeeTipReportGridDataResponse,
  EmployeeTipKPICardsAndChartsData,
  UpdateEmployeeEmergencyContactRequestSchema,
  UpdateEmployeePersonalInfoRequestSchema,
  UpdateEmployeeContactInfoRequestSchema,
  UpdateEmployeeCertificationInfoSchema,
  Job,
  UpdateJobDto,
  ChangeJobActivationStatusDto,
  LaborGroup,
  EmployeeDetailsInfo,
  JobProfile,
  EmployeeContactDetailsInformation,
  UpdateEmployeeDetailsSchemaNew,
  EmploymentHistoryIdResponse,
  UpdateEmployeeNewRequestSchema,
  GetEmployeesRequestSchema,
  EmployeeNew,
  UpdateEmployeeRequestSchema,
  UpdateEmployeeHistorySchema,
  GetPaginatedJobsRequest,
  UpdateJobProfileDto,
  GetJobProfilesRequestSchema,
  CreateSelfEmployeeRequestSchema,
  CreateSelfEmployeeResponse,
  SelfJobProfile,
} from '../types';
import { BaseReportDataRequest } from '../../sales/requests/requests';
import { useSnackbar } from '../../contexts/SnackbarContext';
import routes from '../../constants/routes';

export type LaborRequests = {
  deactivateEmployeeJobcode: (jobCodeId: string, employeeConfigId: string) => Promise<string | void>;
  getEmployeeConfigurations: () => Promise<EmployeeConfigurationsResponse[] | void>;
  getEmployeesByEmpStatus: (activationStatus: EmploymentStatus) => Promise<EmployeeConfigurationsResponse[] | void>;
  getEmployee: (employeeId: string) => Promise<Employee | void>;
  getSelfEmployee: () => Promise<Employee | void>;
  createSelfEmployee: (employee: EmployeeSelfRequestSchema) => Promise<Employee | void>;
  updateEmployee: (
    employeeId: string,
    employee: EmployeeRequestSchema,
    errorMessageMapper?: Record<string, string>
  ) => Promise<UpdateResponse | void>;
  updateSelfEmployee: (updateEmployeeDto: UpdateEmployeeRequestSchema) => Promise<UpdateResponse | void>;
  updateSelfEmployeeInfo: (
    updateSelfEmployeeRequestSchema:
      | UpdateEmployeeEmergencyContactRequestSchema
      | UpdateEmployeeContactInfoRequestSchema
      | UpdateEmployeePersonalInfoRequestSchema
  ) => Promise<UpdateResponse | void>;
  createEmploymentHistory(
    employeeId: string,
    employmentHistory: UpdateEmployeeHistorySchema
  ): Promise<EmploymentHistoryIdResponse | void>;
  getLaborGroups: () => Promise<Paginated<LaborGroupSchema> | void>;
  getLaborGroupById: (laborGroupId: string) => Promise<LaborGroupSchema | void>;
  updateLaborGroupToJobCodeMapping: (laborGroupId: string, jobCodeDefinitionId: string) => Promise<string | void>;
  deleteLaborGroupToJobCodeMapping: (laborGroupId: string, jobCodeDefinitionId: string) => Promise<string | void>;
  getJobCodes: () => Promise<JobCodeDefinition[] | void>;
  getJobCodesByStatus: (activationStatus: ActivationTypes) => Promise<JobCodeDefinition[] | void>;
  getJobcodeById: (jobcodeId: string) => Promise<JobCodeDefinition | void>;
  updateJobcode: (jobcodeId: string, updateJobcodeDto: UpdateJobcodeSchema) => Promise<UpdateResponse | void>;
  getEmployeeJobcodeAssignments: (employeeConfigId: string) => Promise<EmployeeJobCodeAssignment[] | void>;
  updateEmployeeJobcodeAssignment: (
    employeeJobCodeAssignmentId: string,
    employeeJobCodeAssignment: UpdateEmployeeJobCodeAssignmentSchema
  ) => Promise<UpdateResponse | void>;
  getJobcodeHistoryGridData: (body: JobcodeHistoryGridRequest) => Promise<JobcodeHistoryGridResponse | void>;
  updateEmployeeConfiguration: (
    employeeId: string,
    updateEmployeeConfigurationDto: UpdateEmployeeConfigurationSchema
  ) => Promise<UpdateResponse | void>;
  updatePermissions: (
    employeeId: string,
    updateEmployeeConfigurationDto: UpdateEmployeePermissionsSchema
  ) => Promise<UpdateResponse | void>;
  getEmployeeConfiguration: (employeeConfigurationId: string) => Promise<EmployeeConfigurationsResponse | void>;
  createSelfEmploymentConfiguration: (enterpriseUnitId: string) => Promise<Employee | void>;
  getEmployeeConfigurationByEmployeeId: (
    employeeId: string,
    loadEmployeeInfo: boolean
  ) => Promise<EmployeeConfigurationsResponse | void>;
  getEmployeeIdByPosEmployeeId: (posEmployeeId: string) => Promise<EmployeeIdByPosEmployeeIdResponse | void>;
  getContactInfoByEmployeeId: (employeeId: string) => Promise<ContactInformation | void>;
  getBatchPayRatesByEmployeeJobcodes: (
    employeeConfigurationId: string,
    jobcodeIds: string[]
  ) => Promise<PayRate[][] | void>;
  getBatchPayRatesAssignedToJobcodeEmployees: (
    jobcodes: JobCodeDefinition[]
  ) => Promise<{ [jobcodeId: string]: { [employeeId: string]: PayRate[] } } | void>;
  createPayRateForEmployeeConfigJobCode: (
    jobCodeId: string,
    employeeConfigurationId: string,
    payRateData: AddPayRateRequestBody
  ) => Promise<string | void>;
  patchPayRate: (payRateId: string, body: PatchPayRateRequestBody) => Promise<string | void>;
  getSelfContactInformation: () => Promise<ContactInformation | void>;
  updateSelfContactInformation: (contactInfo: ContactInformation) => Promise<UpdateResponse | void>;
  getSelfPersonalInformation: () => Promise<PersonalInformation | void>;
  updateSelfPersonalInformation: (personalInfo: UpdatePersonalInfoSchema) => Promise<UpdateResponse | void>;
  getSelfEmergencyContact: () => Promise<EmergencyContactInformation | void>;
  getSelfEmployeeDetailsInfo: () => Promise<EmployeeDetailsInfo | void>;
  updateSelfEmergencyContact: (emergencyContact: UpdateEmergencyContactRequestSchema) => Promise<UpdateResponse | void>;
  getSelfCertifications: () => Promise<EmployeeCertification[] | void>;
  updateSelfCertification: (certification: UpdateEmployeeCertificationSchema) => Promise<UpdateResponse | void>;
  updateSelfEmployeeCertification: (
    certification: UpdateEmployeeCertificationInfoSchema,
    certificationId: string
  ) => Promise<UpdateResponse | void>;
  createSelfEmployeeCertification: (
    certification: UpdateEmployeeCertificationInfoSchema
  ) => Promise<UpdateResponse | void>;
  updateContactInformation: (
    employeeId: string,
    contactInformation: ContactInfoUpdateRequestSchema
  ) => Promise<UpdateResponse | void>;
  updatePersonalInformation: (
    employeeId: string,
    personalInformation: UpdatePersonalInfoSchema
  ) => Promise<UpdateResponse | void>;
  updateContactInformationNew: (
    employeeId: string,
    contactInformation: EmployeeContactDetailsInformation
  ) => Promise<UpdateResponse | void>;
  updateEmployeeDetailsNew: (
    employeeId: string,
    employeeDetails: UpdateEmployeeDetailsSchemaNew
  ) => Promise<UpdateResponse | void>;
  updateEmergencyContacts: (
    employeeId: string,
    emergencyContacts: UpdateEmergencyContactRequestSchema
  ) => Promise<UpdateResponse | void>;
  updateCertifications: (
    employeeId: string,
    certificationInformation: UpdateEmployeeCertificationSchema
  ) => Promise<UpdateResponse | void>;
  updateEmployeeCertification: (
    employeeId: string,
    certificationId: string,
    updateCertificationsDTO: UpdateEmployeeCertificationInfoSchema
  ) => Promise<UpdateResponse | void>;
  createEmployeeCertification: (
    employeeId: string,
    updateCertificationsDTO: UpdateEmployeeCertificationSchema
  ) => Promise<UpdateResponse | void>;
  getGenericPayrollExportGridData: (
    body: GenericPayrollExportGridDataRequest
  ) => Promise<GenericPayrollExportGridDataResponse[] | void>;
  getOvertimeThresholdReportGridData: (
    body: OvertimeThresholdCommonRequest
  ) => Promise<OvertimeThresholdGridDataResponse | void>;
  getOvertimeThresholdReportKpiCardsData: (
    body: OvertimeThresholdCommonRequest
  ) => Promise<OvertimeThresholdKpiCardsResponse | void>;
  getEmployeeSalesPerformanceReportGridData: (
    body: EmployeeSalesPerformanceCommonRequest
  ) => Promise<EmployeeSalesPerformanceGridDataResponse | void>;
  getEmployeeSalesPerformanceReportKpiCardsData: (
    body: EmployeeSalesPerformanceCommonRequest
  ) => Promise<EmployeeSalesPerformanceKpiCardsResponse | void>;
  getAcaThresholdKpiCardsData: (body: AcaDashboardKPICardsRequest) => Promise<AcaDashboardKPICardsResponse | void>;
  getEmployeeTipReportGridData: (
    body: EmployeeBreakReportCommonRequestSchema
  ) => Promise<EmployeeTipReportGridDataResponse | void>;
  getEmployeeTipReportKpiCardsData: (
    body: EmployeeBreakReportCommonRequestSchema
  ) => Promise<EmployeeTipKPICardsAndChartsData | void>;
  getEmployeeBreakReportGridData: (
    body: EmployeeBreakReportCommonRequestSchema
  ) => Promise<EmployeeBreakReportGridDataResponse | void>;
  getEmployeeBreakReportKpiCardsData: (
    body: EmployeeBreakReportCommonRequestSchema
  ) => Promise<EmployeeBreakReportKpiCardsDataResponse | void>;
  getIntervalSalesAndLaborGridData: (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ) => Promise<IntervalSalesAndLaborGridDataResponse | void>;
  getIntervalSalesAndLaborKPICards: (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ) => Promise<IntervalSalesAndLaborKPIResponse | void>;
  getIntervalSalesAndLaborAverageSales: (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ) => Promise<IntervalSalesAndLaborAverageSalesResponse | void>;
  getApproachingACAHourlyThresholdGridData: (
    body: ApproachingACAHourlyThresholdGridDataRequest
  ) => Promise<ApproachingACAHourlyThresholdGridDataResponse | void>;
  getAcaAverageHoursGridData: (
    body: BaseReportDataRequest
  ) => Promise<ApproachingACAThresholdAverageHoursWorkedGridResponse | void>;
  getEmployeePayrollGridData: (body: EmployeePayrollDto) => Promise<EmployeePayrollGridResponse | void>;
  getEmployeePayrollKPICardsData: (body: EmployeePayrollDto) => Promise<EmployeePayrollKPICardData | void>;
  getEmployeePayrollDetailGridData: (body: EmployeePayrollDto) => Promise<EmployeePayrollDetailDataGridResponse | void>;
  getJobs: (query?: GetPaginatedJobsRequest) => Promise<PaginationResponse<Job> | void>;
  getJobById: (jobId: string) => Promise<Job | void>;
  updateJob: (jobId: string, job: UpdateJobDto) => Promise<void>;
  changeJobActivation: (jobId: string, changeJobActivationDto: ChangeJobActivationStatusDto) => Promise<void>;
  getLaborGroupByIdNewApi: (laborGroupId: string) => Promise<LaborGroup | void>;
  getJobProfilesByJobId: (jobId: string) => Promise<PaginationResponse<JobProfile> | void>;
  getEmployeeByEmployeeId: (empId: string) => Promise<EmployeeNew | void>;
  getEmployeesNew: (params?: GetEmployeesRequestSchema) => Promise<PaginationResponse<EmployeeNew> | void>;
  updateEmployeeNew: (employeeId: string, data: UpdateEmployeeNewRequestSchema) => Promise<UpdateResponse | void>;
  getJobProfilesByEmployeeId: (
    empId: string,
    includeHistory: boolean
  ) => Promise<PaginationResponse<JobProfile> | void>;
  getLaborGroupsNew: () => Promise<PaginationResponse<LaborGroup> | void>;
  getUrlWithEmployeeId: (posEmployeeId: string) => Promise<string | undefined>;
  createJobProfile: (jobProfile: JobProfile) => Promise<string | void>;
  updateJobProfile: (jobProfileId: string, jobProfile: UpdateJobProfileDto) => Promise<string | void>;
  deleteJobProfile: (jobProfileId: string) => Promise<string | void>;
  getAllJobProfiles: (
    requestParameters?: GetJobProfilesRequestSchema
  ) => Promise<PaginationResponse<JobProfile> | void>;
  updateEmploymentHistory: (
    employeeId: string,
    employmentHistoryId: string,
    employmentHistory: UpdateEmployeeHistorySchema
  ) => Promise<void>;
  createSelfEmployeeNew: (employee: CreateSelfEmployeeRequestSchema) => Promise<CreateSelfEmployeeResponse | void>;
  getSelfJobProfile: () => Promise<SelfJobProfile[] | void>;
};

const useLaborRequests = (): LaborRequests => {
  const handleError = useErrorHandler();
  const callBslAndHandleErrors = useCallBslAndHandleErrors();
  const { selectedSite } = useSites();

  const proxies = {
    info: 'employees',
    config: 'employee-configuration',
    sales: 'sales',
    labor: 'labor',
  };

  const Verb = Req.Firebase.HttpVerb;
  const { setSnackbarState } = useSnackbar();
  const { t } = useTranslation();
  /**
   * EMPLOYEE
   */

  const getEmployee = async (employeeId: string): Promise<Employee | void> =>
    callBslAndHandleErrors(
      {
        proxy: 'employees',
        verb: Verb.GET,
        pathSegments: ['employees', employeeId],
      },
      handleError
    );

  const createSelfEmployee = async (employeeInfo: EmployeeSelfRequestSchema): Promise<Employee | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.info,
        pathSegments: ['employees', 'self'],
        payload: employeeInfo,
      },
      handleError
    );

  const createSelfEmploymentConfiguration = async (enterpriseUnitId: string): Promise<Employee | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.info,
        pathSegments: ['employees', 'euid', 'self'],
        enterpriseUnit: enterpriseUnitId,
      },
      handleError
    );

  const getSelfEmployee = async (): Promise<Employee | void> =>
    callBslAndHandleErrors(
      {
        proxy: 'employees',
        verb: Verb.GET,
        pathSegments: ['employees', 'self'],
      },
      handleError
    );

  const updateEmployee = async (
    employeeId: string,
    employee: EmployeeRequestSchema,
    errorMessageMapper?: Record<string, string>
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.info,
        pathSegments: ['employees'],
        payload: employee,
        queryParams: [{ kind: 'single', key: 'id', value: employeeId }],
      },
      handleError,
      errorMessageMapper
    );

  const updateContactInformation = async (
    employeeId: string,
    contactInformation: ContactInfoUpdateRequestSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.info,
        pathSegments: ['contact-information', `${employeeId}`],
        payload: contactInformation,
      },
      handleError
    );

  const updatePersonalInformation = async (
    employeeId: string,
    personalInformation: UpdatePersonalInfoSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.info,
        pathSegments: ['personal-information', `${employeeId}`],
        payload: personalInformation,
      },
      handleError
    );

  const updateContactInformationNew = async (
    employeeId: string,
    contactInformation: EmployeeContactDetailsInformation
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['employees', `${employeeId}`, 'contact'],
        payload: contactInformation,
      },
      handleError
    );

  const updateEmployeeDetailsNew = async (
    employeeId: string,
    employeeDetails: UpdateEmployeeDetailsSchemaNew
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['employees', `${employeeId}`],
        payload: employeeDetails,
      },
      handleError
    );

  const updateEmergencyContacts = async (
    employeeId: string,
    emergencyContacts: UpdateEmergencyContactRequestSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.info,
        pathSegments: ['emergency-contacts', `${employeeId}`],
        payload: emergencyContacts,
      },
      handleError
    );

  const updateSelfEmployee = async (updateEmployeeDto: UpdateEmployeeRequestSchema): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.PATCH, proxy: proxies.info, pathSegments: ['employees', 'self'], payload: updateEmployeeDto },
      handleError
    );

  /**
   * EMPLOYEE CONFIG
   */
  // helper method to get query params for get employees

  const getEmployeesParams = (
    params?: GetEmployeesRequestSchema
  ): Array<Req.Firebase.MultiQueryParam | Req.Firebase.SingleQueryParam> => {
    const queryParams: Array<Req.Firebase.MultiQueryParam | Req.Firebase.SingleQueryParam> = [
      // TODO: Setting this to max page size allowed for now. Update once pagination is completely implemented.
      { kind: 'single', key: 'pagesize', value: '100' },
    ];

    if (params?.employmentStatus) {
      queryParams.push({ kind: 'single', key: 'employmentStatus', value: params.employmentStatus });
    }
    if (params?.employeeIds) {
      queryParams.push({ kind: 'multi', key: 'employeeIds', value: params.employeeIds });
    }
    if (params?.posEmployeeId) {
      queryParams.push({ kind: 'single', key: 'posEmployeeId', value: params.posEmployeeId });
    }
    if (params?.includeJobProfileCount) {
      queryParams.push({ kind: 'single', key: 'includeJobProfileCount', value: params.includeJobProfileCount });
    }
    return queryParams;
  };

  const getJobProfilesParams = (
    params?: GetJobProfilesRequestSchema
  ): Array<Req.Firebase.MultiQueryParam | Req.Firebase.SingleQueryParam> => {
    const queryParams: Array<Req.Firebase.MultiQueryParam | Req.Firebase.SingleQueryParam> = [
      { kind: 'single', key: 'pagesize', value: '100' },
    ];

    if (params?.pagenum) {
      queryParams.push({ kind: 'single', key: 'pagenum', value: String(params.pagenum) });
    }

    if (params?.jobProfileIds) {
      queryParams.push({ kind: 'multi', key: 'jobProfileIds', value: params.jobProfileIds });
    }

    return queryParams;
  };

  const getEmployeeConfigurations = async (): Promise<EmployeeConfigurationsResponse[] | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.config,
        pathSegments: ['employee-configurations', 'not-paginated'],
        verb: Verb.GET,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getEmployeesByEmpStatus = async (
    activationStatus: EmploymentStatus
  ): Promise<EmployeeConfigurationsResponse[] | void> => {
    if (selectedSite.enterpriseUnitId) {
      return callBslAndHandleErrors(
        {
          verb: Verb.GET,
          proxy: proxies.config,
          pathSegments: ['employee-configurations', 'not-paginated'],
          queryParams: [
            { kind: 'single', key: 'filterByColumns[]', value: 'activationStatus' },
            { kind: 'single', key: 'filterByKeyword', value: activationStatus },
          ],
          enterpriseUnit: selectedSite.enterpriseUnitId,
        },
        handleError
      );
    }
    return [];
  };

  const updateEmployeeConfiguration = async (
    employeeConfigurationId: string,
    updateEmployeeConfigurationDto: UpdateEmployeeConfigurationSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.config,
        pathSegments: ['employee-configurations', employeeConfigurationId],
        payload: updateEmployeeConfigurationDto,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const updatePermissions = async (
    employeeConfigurationId: string,
    updateEmployeeConfigurationDto: UpdateEmployeePermissionsSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.config,
        pathSegments: ['employee-configurations', employeeConfigurationId],
        payload: updateEmployeeConfigurationDto,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getEmployeeConfiguration = async (
    employeeConfigurationId: string
  ): Promise<EmployeeConfigurationsResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['employee-configurations', employeeConfigurationId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getEmployeeConfigurationByEmployeeId = async (
    employeeId: string,
    loadEmployeeInfo = false
  ): Promise<EmployeeConfigurationsResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['employee-configurations', 'employeeId', employeeId],
        queryParams: [{ kind: 'single', key: 'loadEmployeeInfo', value: loadEmployeeInfo.toString() }],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getEmployeeIdByPosEmployeeId = async (
    posEmployeeId: string
  ): Promise<EmployeeIdByPosEmployeeIdResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['employee-configurations', 'id-by-pos-emp-id', posEmployeeId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getContactInfoByEmployeeId = async (employeeId: string): Promise<ContactInformation | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.info,
        pathSegments: ['contact-information', employeeId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getBatchPayRatesByEmployeeJobcodes = async (
    employeeConfigurationId: string,
    jobcodeIds: string[]
  ): Promise<PayRate[][] | void> => {
    const payRates = await Promise.all(
      jobcodeIds.flatMap(
        async (jobcodeId) =>
          callBslAndHandleErrors(
            {
              verb: Verb.GET,
              proxy: proxies.config,
              pathSegments: [
                'employee-configurations',
                employeeConfigurationId,
                'job-code-definitions',
                jobcodeId,
                'pay-rates',
              ],
              enterpriseUnit: selectedSite.enterpriseUnitId,
            },
            handleError
          ) as unknown as PayRate[]
      )
    );
    return payRates;
  };

  const getBatchPayRatesAssignedToJobcodeEmployees = async (
    jobcodes: JobCodeDefinition[]
  ): Promise<{ [jobcodeId: string]: { [employeeId: string]: PayRate[] } } | void> => {
    const result = await Promise.all(
      jobcodes.flatMap(async (jobcode) => {
        if (jobcode.employees) {
          const employeePayRates = await Promise.all(
            jobcode.employees.flatMap(async (employee) => {
              const payRates = (await callBslAndHandleErrors(
                {
                  verb: Verb.GET,
                  proxy: proxies.config,
                  pathSegments: [
                    'employee-configurations',
                    employee.id,
                    'job-code-definitions',
                    jobcode.id,
                    'pay-rates',
                  ],
                  enterpriseUnit: selectedSite.enterpriseUnitId,
                },
                handleError
              )) as unknown as PayRate[];

              return { [employee.id]: payRates };
            })
          );

          return { [jobcode.id]: employeePayRates.reduce((acc, curr) => ({ ...acc, ...curr }), {}) };
        }
        return {};
      })
    );
    return result.reduce((acc, curr) => ({ ...acc, ...curr }), {});
  };

  const createPayRateForEmployeeConfigJobCode = async (
    jobCodeId: string,
    employeeConfigurationId: string,
    payRateData: AddPayRateRequestBody
  ): Promise<string | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.config,
        pathSegments: [
          'job-code-definitions',
          jobCodeId,
          'employee-configurations',
          employeeConfigurationId,
          'pay-rate',
        ],
        payload: payRateData,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  /**
   * PAY RATES
   */
  const patchPayRate = async (payRateId: string, body: PatchPayRateRequestBody): Promise<string | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.config,
        pathSegments: ['pay-rates', payRateId],
        payload: body,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  // Deactivate a job code for a specific employee.
  const deactivateEmployeeJobcode = async (jobCodeId: string, employeeConfigId: string): Promise<string | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.DELETE,
        proxy: proxies.config,
        pathSegments: ['job-code-definitions', jobCodeId, 'employee-configurations', employeeConfigId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getLaborGroups = (): Promise<Paginated<LaborGroupSchema> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['labor-groups'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getLaborGroupById = (laborGroupId: string): Promise<LaborGroupSchema | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['labor-groups', laborGroupId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  // TODO : Rename once all pages are updated to use new API's
  const getLaborGroupByIdNewApi = (laborGroupId: string): Promise<LaborGroup | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['labor-groups', laborGroupId],
      },
      handleError
    );

  const updateLaborGroupToJobCodeMapping = (
    laborGroupId: string,
    jobCodeDefinitionId: string
  ): Promise<string | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.config,
        pathSegments: ['labor-groups', laborGroupId, 'job-code-definitions', jobCodeDefinitionId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const deleteLaborGroupToJobCodeMapping = (
    laborGroupId: string,
    jobCodeDefinitionId: string
  ): Promise<string | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.DELETE,
        proxy: proxies.config,
        pathSegments: ['labor-groups', laborGroupId, 'job-code-definitions', jobCodeDefinitionId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  /**
   * JOB CODES
   */

  const getJobCodes = async (): Promise<JobCodeDefinition[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['job-code-definitions', 'not-paginated'],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getJobCodesByStatus = async (activationStatus: ActivationTypes): Promise<JobCodeDefinition[] | void> => {
    if (selectedSite.enterpriseUnitId) {
      return callBslAndHandleErrors(
        {
          verb: Verb.GET,
          proxy: proxies.config,
          pathSegments: ['job-code-definitions', 'not-paginated'],
          queryParams: [
            { kind: 'single', key: 'filterByColumns[]', value: 'activationStatus' },
            { kind: 'single', key: 'filterByKeyword', value: activationStatus.toString() },
          ],
          enterpriseUnit: selectedSite.enterpriseUnitId,
        },
        handleError
      );
    }
    return [];
  };

  const getJobcodeById = (jobcodeId: string): Promise<JobCodeDefinition | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['job-code-definitions', jobcodeId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const updateJobcode = (jobcodeId: string, updateJobcodeDto: UpdateJobcodeSchema): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.config,
        pathSegments: ['job-code-definitions', jobcodeId],
        enterpriseUnit: selectedSite.enterpriseUnitId,
        payload: updateJobcodeDto,
      },
      handleError
    );

  const getEmployeeJobcodeAssignments = (employeeConfigId: string): Promise<EmployeeJobCodeAssignment[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.config,
        pathSegments: ['employee-job-code-assignment'],
        queryParams: [
          { kind: 'single', key: 'filterByColumns[]', value: 'employeeId' },
          { kind: 'single', key: 'filterByKeyword', value: employeeConfigId },
        ],
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const updateEmployeeJobcodeAssignment = (
    employeeJobCodeAssignmentId: string,
    employeeJobCodeAssignment: UpdateEmployeeJobCodeAssignmentSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.config,
        pathSegments: ['employee-job-code-assignment', employeeJobCodeAssignmentId],
        payload: employeeJobCodeAssignment,
        enterpriseUnit: selectedSite.enterpriseUnitId,
      },
      handleError
    );

  const getJobcodeHistoryGridData = (body: JobcodeHistoryGridRequest): Promise<JobcodeHistoryGridResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['jobcode-history', 'grid-data'],
        payload: body,
      },
      handleError
    );

  /**
   * CONTACT INFORMATION
   */

  const getSelfContactInformation = async (): Promise<ContactInformation | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.info,
        pathSegments: ['contact-information', 'self'],
      },
      handleError
    );

  const updateSelfContactInformation = async (contactInfo: ContactInformation): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.PATCH, proxy: proxies.info, pathSegments: ['contact-information', 'self'], payload: contactInfo },
      handleError
    );

  /**
   * PERSONAL INFORMATION
   */

  const getSelfPersonalInformation = async (): Promise<PersonalInformation | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.info,
        pathSegments: ['personal-information', 'self'],
      },
      handleError
    );

  const updateSelfPersonalInformation = async (
    personalInfo: UpdatePersonalInfoSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.PATCH, proxy: proxies.info, pathSegments: ['personal-information', 'self'], payload: personalInfo },
      handleError
    );

  /**
   * EMERGENCY CONTACT
   */

  const getSelfEmergencyContact = async (): Promise<EmergencyContactInformation | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.info,
        pathSegments: ['emergency-contacts', 'self'],
      },
      handleError
    );

  const getSelfEmployeeDetailsInfo = async (): Promise<EmployeeDetailsInfo | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['self', 'employee-details'],
      },
      handleError
    );

  const updateSelfEmergencyContact = async (
    emergencyContact: UpdateEmergencyContactRequestSchema | null
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.info,
        pathSegments: ['emergency-contacts', 'self'],
        payload: emergencyContact,
      },
      handleError
    );

  /**
   * Self Update Employee Info
   */

  const updateSelfEmployeeInfo = async (
    updateSelfEmployeeRequestSchema:
      | UpdateEmployeeEmergencyContactRequestSchema
      | UpdateEmployeeContactInfoRequestSchema
      | UpdateEmployeePersonalInfoRequestSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['self', 'employee-details'],
        payload: updateSelfEmployeeRequestSchema,
      },
      handleError
    );

  /**
   * CERTIFICATIONS
   */

  const getSelfCertifications = async (): Promise<EmployeeCertification[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.info,
        pathSegments: ['certifications', 'self'],
      },
      handleError
    );

  const updateCertifications = async (
    employeeId: string,
    updateCertificationsDTO: UpdateEmployeeCertificationSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.info,
        pathSegments: ['certifications', `${employeeId}`],
        payload: updateCertificationsDTO,
      },
      handleError
    );

  const updateEmployeeCertification = async (
    employeeId: string,
    certificationId: string,
    updateCertificationsDTO: UpdateEmployeeCertificationInfoSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['employees', `${employeeId}`, 'certifications', `${certificationId}`],
        payload: updateCertificationsDTO,
      },
      handleError
    );

  const createEmployeeCertification = async (
    employeeId: string,
    updateCertificationsDTO: UpdateEmployeeCertificationInfoSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.labor,
        pathSegments: ['employees', `${employeeId}`, 'certifications'],
        payload: updateCertificationsDTO,
      },
      handleError
    );

  const updateSelfCertification = async (
    certification: UpdateEmployeeCertificationSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.PATCH, proxy: proxies.info, pathSegments: ['certifications', 'self'], payload: certification },
      handleError
    );

  const updateSelfEmployeeCertification = async (
    certification: UpdateEmployeeCertificationInfoSchema,
    certificationId: string
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['self', 'certifications', `${certificationId}`],
        payload: certification,
      },
      handleError
    );
  const createSelfEmployeeCertification = async (
    certification: UpdateEmployeeCertificationInfoSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.labor,
        pathSegments: ['self', 'certifications'],
        payload: certification,
      },
      handleError
    );

  /**
   * EMPLOYEE SALES PERFORMANCE
   */

  const getEmployeeSalesPerformanceReportGridData = async (
    body: EmployeeSalesPerformanceCommonRequest
  ): Promise<EmployeeSalesPerformanceGridDataResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['employee-sales', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getEmployeeSalesPerformanceReportKpiCardsData = async (
    body: EmployeeSalesPerformanceCommonRequest
  ): Promise<EmployeeSalesPerformanceKpiCardsResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['employee-sales', 'kpi-cards'],
        payload: body,
      },
      handleError
    );

  /**
   * APPROACHING OT THRESHOLD
   */

  const getOvertimeThresholdReportGridData = async (
    body: OvertimeThresholdCommonRequest
  ): Promise<OvertimeThresholdGridDataResponse | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['approaching-overtime-threshold', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getOvertimeThresholdReportKpiCardsData = async (
    body: OvertimeThresholdCommonRequest
  ): Promise<OvertimeThresholdKpiCardsResponse | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['approaching-overtime-threshold', 'kpi-cards'],
        payload: body,
      },
      handleError
    );

  /**
   * GENERIC PAYROLL EXPORT
   */
  const getGenericPayrollExportGridData = async (
    body: GenericPayrollExportGridDataRequest
  ): Promise<GenericPayrollExportGridDataResponse[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['generic-payroll-export'],
        payload: body,
      },
      handleError
    );

  /**
   * EMPLOYEE TIP REPORT
   */

  const getEmployeeTipReportGridData = async (
    body: EmployeeBreakReportCommonRequestSchema
  ): Promise<EmployeeTipReportGridDataResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['employee-tip', 'grid-data'], payload: body },
      handleError
    );

  const getEmployeeTipReportKpiCardsData = async (
    body: EmployeeBreakReportCommonRequestSchema
  ): Promise<EmployeeTipKPICardsAndChartsData | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['employee-tip', 'kpi-cards'], payload: body },
      handleError
    );

  /**
   * EMPLOYEE BREAK REPORT
   */
  const getEmployeeBreakReportGridData = async (
    body: EmployeeBreakReportCommonRequestSchema
  ): Promise<EmployeeBreakReportGridDataResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['employee-break', 'grid-data'], payload: body },
      handleError
    );

  const getEmployeeBreakReportKpiCardsData = async (
    body: EmployeeBreakReportCommonRequestSchema
  ): Promise<EmployeeBreakReportKpiCardsDataResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['employee-break', 'kpi-cards'], payload: body },
      handleError
    );

  /**
   * INTERVAL SALES AND LABOR REPORT
   */

  const getIntervalSalesAndLaborGridData = async (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ): Promise<IntervalSalesAndLaborGridDataResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['interval-sales-labor', 'grid-data'], payload: body },
      handleError
    );

  const getIntervalSalesAndLaborKPICards = async (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ): Promise<IntervalSalesAndLaborKPIResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['interval-sales-labor', 'kpi-cards'], payload: body },
      handleError
    );

  const getIntervalSalesAndLaborAverageSales = async (
    body: IntervalSalesAndLaborReportCommonRequestSchema
  ): Promise<IntervalSalesAndLaborAverageSalesResponse | void> =>
    callBslAndHandleErrors(
      { verb: Verb.POST, proxy: proxies.sales, pathSegments: ['interval-sales-labor', 'average-sales'], payload: body },
      handleError
    );

  /**
   * APPROACHING ACA THRESHOLD
   */

  const getAcaThresholdKpiCardsData = async (
    body: AcaDashboardKPICardsRequest
  ): Promise<AcaDashboardKPICardsResponse | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['approaching-aca-threshold', 'dashboard', 'kpi-cards'],
        payload: body,
      },
      handleError
    );

  const getApproachingACAHourlyThresholdGridData = async (
    body: ApproachingACAHourlyThresholdGridDataRequest
  ): Promise<ApproachingACAHourlyThresholdGridDataResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['approaching-aca-threshold', 'hourly-threshold', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getAcaAverageHoursGridData = async (
    body: BaseReportDataRequest
  ): Promise<ApproachingACAThresholdAverageHoursWorkedGridResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.sales,
        pathSegments: ['approaching-aca-threshold', 'employee-average', 'grid-data'],
        payload: body,
      },
      handleError
    );

  /**
   * Employee Payroll Report
   */

  const getEmployeePayrollDetailGridData = async (
    body: EmployeePayrollDto
  ): Promise<EmployeePayrollDetailDataGridResponse | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['employee-payroll', 'detail', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getEmployeePayrollKPICardsData = async (body: EmployeePayrollDto): Promise<EmployeePayrollKPICardData | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['employee-payroll', 'kpi-cards'],
        payload: body,
      },
      handleError
    );

  const getEmployeePayrollGridData = async (body: EmployeePayrollDto): Promise<EmployeePayrollGridResponse | void> =>
    callBslAndHandleErrors(
      {
        proxy: proxies.sales,
        verb: Verb.POST,
        pathSegments: ['employee-payroll', 'summary', 'grid-data'],
        payload: body,
      },
      handleError
    );

  const getJobs = async (query?: GetPaginatedJobsRequest): Promise<PaginationResponse<Job> | void> => {
    const queryParams: Array<MultiQueryParam | SingleQueryParam> = [];

    if (query?.ids?.length) {
      queryParams.push({ kind: 'multi', key: 'ids[]', value: query?.ids });
    }

    if (query?.pagenum) {
      queryParams.push({ kind: 'single', key: 'pagenum', value: String(query?.pagenum) });
    }

    if (query?.includeJobProfileCount) {
      queryParams.push({ kind: 'single', key: 'includeJobProfileCount', value: 'true' });
    }

    return callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['jobs'],
        queryParams: [
          ...queryParams,
          // TODO: Setting this to max page size allowed for now. Update once pagination is completely implemented.
          { kind: 'single', key: 'pagesize', value: '100' },
        ],
      },
      handleError
    );
  };

  const getJobById = async (jobId: string): Promise<Job | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['jobs', jobId],
        queryParams: [{ kind: 'single', key: 'includeJobProfileCount', value: 'true' }],
      },
      handleError
    );

  const updateJob = async (jobId: string, job: UpdateJobDto): Promise<void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['jobs', jobId],
        payload: job,
      },
      handleError
    );

  const changeJobActivation = async (
    jobid: string,
    changeJobActivationDto: ChangeJobActivationStatusDto
  ): Promise<void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PATCH,
        proxy: proxies.labor,
        pathSegments: ['jobs', jobid, 'changeActivation'],
        payload: changeJobActivationDto,
      },
      handleError
    );

  const getEmployeesNew = async (params?: GetEmployeesRequestSchema): Promise<PaginationResponse<EmployeeNew> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['employees'],
        queryParams: getEmployeesParams(params),
      },
      handleError
    );

  const getJobProfilesByJobId = async (jobId: string): Promise<PaginationResponse<JobProfile> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['jobs', jobId, 'jobProfiles'],
        queryParams: [{ kind: 'single', key: 'jobId', value: jobId }],
      },
      handleError
    );

  const getEmployeeByEmployeeId = async (empId: string): Promise<EmployeeNew | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['employees', empId],
      },
      handleError
    );

  const updateEmployeeNew = async (
    employeeId: string,
    data: UpdateEmployeeNewRequestSchema
  ): Promise<UpdateResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['employees', employeeId],
        payload: data,
      },
      handleError
    );

  const getJobProfilesByEmployeeId = async (
    empId: string,
    includeHistory?: boolean
  ): Promise<PaginationResponse<JobProfile> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['employees', empId, 'job-profiles'],
        queryParams: [
          { kind: 'single', key: 'includeHistory', value: (includeHistory ?? false).toString() },
          { kind: 'single', key: 'pagesize', value: '100' },
        ],
      },
      handleError
    );
  const getLaborGroupsNew = (): Promise<PaginationResponse<LaborGroup> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['labor-groups'],
      },
      handleError
    );

  const getUrlWithEmployeeId = (posEmployeeId: string): Promise<string | undefined> =>
    callBslAndHandleErrors<PaginationResponse<EmployeeNew>, void>(
      {
        proxy: proxies.labor,
        verb: Verb.GET,
        pathSegments: ['employees'],
        queryParams: [{ kind: 'single', key: 'posEmployeeId', value: posEmployeeId.toString() }],
      },
      handleError
    ).then((res: PaginationResponse<EmployeeNew> | void) => {
      const castRes = res as PaginationResponse<EmployeeNew>;
      if (castRes.pageContent.length === 0) {
        setSnackbarState({
          open: true,
          message: t('errors.employeeNotFound'),
          color: 'error',
        });
        return undefined;
      }
      const { employeeId } = castRes.pageContent[0];
      const route = routes.EMPLOYEE_GENERAL_INFORMATION_PAGE.replace(':employeeId', employeeId);
      return window.location.origin + route;
    });

  const createEmploymentHistory = async (
    employeeId: string,
    employmentHistory: UpdateEmployeeHistorySchema
  ): Promise<EmploymentHistoryIdResponse | void> => {
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.labor,
        pathSegments: ['employees', `${employeeId}`, 'employment-history'],
        payload: employmentHistory,
      },
      handleError
    );
  };

  const updateEmploymentHistory = async (
    employeeId: string,
    employmentHistoryId: string,
    employmentHistory: UpdateEmployeeHistorySchema
  ): Promise<void> => {
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['employees', employeeId, 'employment-history', employmentHistoryId],
        payload: employmentHistory,
      },
      handleError
    );
  };

  const createJobProfile = async (jobProfile: JobProfile): Promise<string | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.labor,
        pathSegments: ['job-profiles'],
        payload: jobProfile,
      },
      handleError
    );

  const updateJobProfile = async (jobProfileId: string, jobProfile: UpdateJobProfileDto): Promise<void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.PUT,
        proxy: proxies.labor,
        pathSegments: ['job-profiles', jobProfileId],
        payload: jobProfile,
      },
      handleError
    );

  const deleteJobProfile = async (jobProfileId: string): Promise<string | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.DELETE,
        proxy: proxies.labor,
        pathSegments: ['job-profiles', jobProfileId],
      },
      handleError
    );

  const getAllJobProfiles = async (
    pageParam?: GetJobProfilesRequestSchema
  ): Promise<PaginationResponse<JobProfile> | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['job-profiles'],
        queryParams: getJobProfilesParams(pageParam),
      },
      handleError
    );

  const createSelfEmployeeNew = async (
    employee: CreateSelfEmployeeRequestSchema
  ): Promise<CreateSelfEmployeeResponse | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.POST,
        proxy: proxies.labor,
        pathSegments: ['self', 'employee'],
        payload: employee,
      },
      handleError
    );

  const getSelfJobProfile = async (): Promise<SelfJobProfile[] | void> =>
    callBslAndHandleErrors(
      {
        verb: Verb.GET,
        proxy: proxies.labor,
        pathSegments: ['self', 'job-profiles'],
      },
      handleError
    );

  return {
    deactivateEmployeeJobcode,
    getEmployeeConfigurations,
    getEmployeesByEmpStatus,
    getEmployee,
    createSelfEmployee,
    createEmploymentHistory,
    getSelfEmployee,
    updateEmployee,
    updateSelfEmployee,
    getLaborGroups,
    getLaborGroupById,
    updateLaborGroupToJobCodeMapping,
    deleteLaborGroupToJobCodeMapping,
    getJobCodes,
    getJobCodesByStatus,
    getJobcodeById,
    updateJobcode,
    getEmployeeJobcodeAssignments,
    updateEmployeeJobcodeAssignment,
    getJobcodeHistoryGridData,
    updateEmployeeConfiguration,
    updatePermissions,
    createSelfEmploymentConfiguration,
    getEmployeeConfiguration,
    getEmployeeConfigurationByEmployeeId,
    getEmployeeByEmployeeId,
    getEmployeeIdByPosEmployeeId,
    getContactInfoByEmployeeId,
    createPayRateForEmployeeConfigJobCode,
    patchPayRate,
    getBatchPayRatesByEmployeeJobcodes,
    getBatchPayRatesAssignedToJobcodeEmployees,
    getSelfContactInformation,
    updateSelfContactInformation,
    getSelfPersonalInformation,
    updateSelfPersonalInformation,
    getSelfEmergencyContact,
    getSelfEmployeeDetailsInfo,
    updateSelfEmergencyContact,
    getSelfCertifications,
    updateSelfCertification,
    updateSelfEmployeeCertification,
    createSelfEmployeeCertification,
    updateContactInformation,
    updateEmergencyContacts,
    updatePersonalInformation,
    updateContactInformationNew,
    updateEmployeeDetailsNew,
    updateCertifications,
    updateEmployeeCertification,
    createEmployeeCertification,
    getGenericPayrollExportGridData,
    getOvertimeThresholdReportGridData,
    getOvertimeThresholdReportKpiCardsData,
    getEmployeeTipReportGridData,
    getEmployeeTipReportKpiCardsData,
    getEmployeeBreakReportGridData,
    getAcaThresholdKpiCardsData,
    getEmployeeBreakReportKpiCardsData,
    getIntervalSalesAndLaborGridData,
    getIntervalSalesAndLaborKPICards,
    getIntervalSalesAndLaborAverageSales,
    getEmployeeSalesPerformanceReportGridData,
    getEmployeeSalesPerformanceReportKpiCardsData,
    getApproachingACAHourlyThresholdGridData,
    getAcaAverageHoursGridData,
    getEmployeePayrollDetailGridData,
    getEmployeePayrollKPICardsData,
    getEmployeePayrollGridData,
    getJobs,
    getJobById,
    updateJob,
    changeJobActivation,
    getLaborGroupByIdNewApi,
    getJobProfilesByJobId,
    getEmployeesNew,
    updateEmployeeNew,
    getJobProfilesByEmployeeId,
    updateSelfEmployeeInfo,
    getLaborGroupsNew,
    getUrlWithEmployeeId,
    createJobProfile,
    updateJobProfile,
    deleteJobProfile,
    getAllJobProfiles,
    updateEmploymentHistory,
    createSelfEmployeeNew,
    getSelfJobProfile,
  };
};

export default useLaborRequests;
