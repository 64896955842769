import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOff from '@mui/icons-material/HighlightOff';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

export enum DataGridActionsMenuItems {
  Deactivate = 'deactivate',
  Activate = 'activate',
  Delete = 'delete',
  Edit = 'edit',
}

type CustomActionsMenuCellProps = {
  id?: string;
  actions: DataGridActionsMenuItems[];
  onClickFunctions?: Partial<Record<DataGridActionsMenuItems, () => void>>;
  tooltipTitles?: Partial<Record<DataGridActionsMenuItems, string>>;
  extraPermissions?: Partial<Record<DataGridActionsMenuItems, boolean>>;
  disabled?: Partial<Record<DataGridActionsMenuItems, boolean>>;
  isActive?: boolean;
};

function CustomActionsMenuCell(props: CustomActionsMenuCellProps) {
  const { id, isActive, actions, onClickFunctions, tooltipTitles, extraPermissions, disabled } = props;
  const { t } = useTranslation();

  // menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // custom actions
  const actionOptions = [];
  const tooltipProps: Partial<TooltipProps> = {
    placement: 'left',
    arrow: true,
    slotProps: {
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      },
    },
  };

  const getPermissionValue = (key: DataGridActionsMenuItems) => {
    if (extraPermissions) {
      if (Object.keys(extraPermissions).includes(key)) {
        return extraPermissions[key];
      }
      return true;
    }
    return true;
  };

  const isDisabled = (key: DataGridActionsMenuItems) => {
    if (disabled) {
      if (Object.keys(disabled).includes(key)) {
        return disabled[key];
      }
    }
    return false;
  };

  const onClick = (key: DataGridActionsMenuItems) => {
    if (onClickFunctions) {
      onClickFunctions[key]?.();
      handleClose();
    }
  };

  if (actions.includes(DataGridActionsMenuItems.Edit) && getPermissionValue(DataGridActionsMenuItems.Edit)) {
    const menuItem = (
      <Tooltip
        {...tooltipProps}
        title={tooltipTitles ? tooltipTitles[DataGridActionsMenuItems.Edit] : undefined}
        key={uuidv4()}
      >
        <MenuItem
          data-testid={`${id}-edit-btn`}
          onClick={() => onClick(DataGridActionsMenuItems.Edit)}
          disabled={isDisabled(DataGridActionsMenuItems.Edit)}
        >
          <ListItemIcon>
            <EditIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>{t('buttonText.edit')}</ListItemText>
        </MenuItem>
      </Tooltip>
    );
    actionOptions.push(menuItem);
  }

  if (
    actions.includes(DataGridActionsMenuItems.Activate) &&
    !isActive &&
    getPermissionValue(DataGridActionsMenuItems.Activate)
  ) {
    const menuItem = (
      <Tooltip
        {...tooltipProps}
        title={tooltipTitles ? tooltipTitles[DataGridActionsMenuItems.Activate] : undefined}
        key={uuidv4()}
      >
        <MenuItem
          data-testid={`${id}-activate-btn`}
          onClick={() => onClick(DataGridActionsMenuItems.Activate)}
          disabled={isDisabled(DataGridActionsMenuItems.Activate)}
        >
          <ListItemIcon>
            <CheckCircleIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>{t('buttonText.activate')}</ListItemText>
        </MenuItem>
      </Tooltip>
    );
    actionOptions.push(menuItem);
  }

  if (
    actions.includes(DataGridActionsMenuItems.Deactivate) &&
    isActive &&
    getPermissionValue(DataGridActionsMenuItems.Deactivate)
  ) {
    const menuItem = (
      <Tooltip
        {...tooltipProps}
        title={tooltipTitles ? tooltipTitles[DataGridActionsMenuItems.Deactivate] : undefined}
        key={uuidv4()}
      >
        <MenuItem
          data-testid={`${id}-deactivate-btn`}
          onClick={() => onClick(DataGridActionsMenuItems.Deactivate)}
          disabled={isDisabled(DataGridActionsMenuItems.Deactivate)}
        >
          <ListItemIcon>
            <HighlightOff fontSize='small' />
          </ListItemIcon>
          <ListItemText>{t('buttonText.deactivate')}</ListItemText>
        </MenuItem>
      </Tooltip>
    );
    actionOptions.push(menuItem);
  }

  if (actions.includes(DataGridActionsMenuItems.Delete) && getPermissionValue(DataGridActionsMenuItems.Delete)) {
    const menuItem = (
      <Tooltip
        {...tooltipProps}
        title={tooltipTitles ? tooltipTitles[DataGridActionsMenuItems.Delete] : undefined}
        key={uuidv4()}
      >
        <MenuItem
          data-testid={`${id}-delete-btn`}
          onClick={() => onClick(DataGridActionsMenuItems.Delete)}
          disabled={isDisabled(DataGridActionsMenuItems.Delete)}
        >
          <ListItemIcon>
            <DeleteIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>{t('buttonText.delete')}</ListItemText>
        </MenuItem>
      </Tooltip>
    );
    actionOptions.push(menuItem);
  }

  return (
    <>
      <IconButton
        id='actions'
        aria-label='actions'
        aria-controls={open ? 'actions-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        data-testid='actions-menu-button'
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='actions-menu'
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'actions',
        }}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {actionOptions}
      </Menu>
    </>
  );
}

CustomActionsMenuCell.defaultProps = {
  onClickFunctions: undefined,
  tooltipTitles: undefined,
  isActive: false,
  extraPermissions: undefined,
  disabled: false,
  id: '',
};

export default CustomActionsMenuCell;
